/* The Modal (background) */

.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}


/* Modal Content/Box */

.modal-content {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}


/* The Close Button */

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    z-index: 10;
    right: 24px;
    position: absolute
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}