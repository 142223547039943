.App-header {
    background-color: #282c34;
    min-height: 10vh;
    height: 80vh;
    font-size: calc(10px + 2vmin);
    color: white;
}

.Header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 10vh;
    height: 65vh;
}

.Div-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Button {
    background-color: #0066ff;
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    border-radius: 24px;
    font-weight: bold;
}

.Title-name {
    color: darkgrey;
}

.Button-scroll {
    background-color: #0066ff;
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    font-weight: bold;
}