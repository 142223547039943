.About-box {
    display: flex;
    justify-content: space-between;
    margin-left: 24px;
    margin-right: 24px;
}

.About-text {
    font-size: 16pt;
    font-weight: normal;
}