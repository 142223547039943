.Projects-container {
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    margin-right: 16px;
}

@media only screen and (max-width: 800px) {
    .Projects-container {
        flex-direction: column;
    }
}