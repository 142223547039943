.App-footer {
    min-height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 16px;
}

hr {
    margin-top: 32px;
    margin-bottom: 16px;
    width: 90%;
}

.Footer-link {
    color: #61dafb;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
}

.copyright {
    display: inline-block;
    color: grey;
}