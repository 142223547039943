.Project-box {
    padding: 4vh 4vw;
    flex: 1;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    margin: 16px;
    position: relative;
}

.Project-box img {
    width: 100%;
}

.App-link {
    color: #61dafb;
    font-size: 24px;
    font-weight: bold;
    margin-top: 12px;
    text-decoration: none;
}

.Buttons-row {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-bottom: 24px;
    padding-left: 4vw;
    padding-right: 4vw;
    width: 100%;
}

.Project-description {
    padding-bottom: 24px;
}

@media only screen and (max-width: 800px) {
    .Project-box {
        margin: 16px 8px;
    }
}