.Section-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}

.Section-h1 {
    font-weight: 300;
    display: inline-block;
    padding-bottom: 5px;
    position: relative;
}

.Section-h1:before {
    content: "";
    position: absolute;
    width: 50%;
    height: 1px;
    bottom: 0;
    left: 25%;
    border-bottom: 1px solid #0066ff;
}