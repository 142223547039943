.Section-box {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    margin-top: 16px;
    margin-bottom: 24px;
    margin-left: 36px;
    margin-right: 36px;
    background: white;
}

@media only screen and (max-width: 800px) {
    .Section-box {
        margin-top: 8px;
        margin-left: 24px;
        margin-right: 24px;
    }
}